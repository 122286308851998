import $$ from 'dom7';

import HomePage from '../pages/home.f7.html';
import LoginPage from '../pages/login.f7.html';
import SignUpPage from '../pages/signup.f7.html';
import FormPage1 from '../pages/form1.f7.html';
import FormPage2 from '../pages/form2.f7.html';
import FormPage3 from '../pages/form3.f7.html';
import ApprovalPage from '../pages/approval.f7.html';
import WalletPage from '../pages/wallet.f7.html';
import BeneficiaryPage from '../pages/beneficiary.f7.html';
import FeesPage from '../pages/fees.f7.html';
import ProfilePage from '../pages/profile.f7.html';
import LoanPage from '../pages/loanDetails.f7.html';

import NotFoundPage from '../pages/404.f7.html';
// import { isMainThread } from 'worker_threads';
// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});
var routes = [
  {
    path: '/',
    component: LoginPage,
    name: 'login',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        // $$('#username').on('keyup', function() {
        //   var val = $$('#username').val();
        //   if(val.length == 10) {
        //     $$('.login-button').removeClass('disabled');
        //   }
        //   else {
        //     if(!$$('.login-button').hasClass('disabled')) {
        //       $$('.login-button').addClass('disabled');
        //     }
        //   }
        // });
        $$('.forgot-password-link').on('click', function() {
          app.dialog.alert('Please contact our support team at <span class="text-accented">support@nexcredloan.com</span>', 'Forgot Password');
        });
        $$('.login-button').on('click', function() {
          if($$('#loginForm')[0].checkValidity()) {
            login();
          }
        });
        function login() {
          var formData = app.form.convertToData('#loginForm');
          //login
          app.methods.request(
            'https://nexcredloan.com/app/php/auth.php',
            {login: true, ...formData},
            'json',
            function() {
              app.preloader.show();
            },
            function(data) {
              app.preloader.hide();
              if(data.status == 'success') {
                setTimeout(() => {
                  localStorage.setItem('email', data.email);
                  localStorage.setItem('authToken', data.authToken);
                  localStorage.setItem('mode', data.mode);
                  app.methods.getUserData(
                    function(user) {
                      if(user.loanData.isApplied) {
                        localStorage.setItem('maxLoan', '100000');
                        if(user.loanData.isApproved) {
                          app.dialog.alert(data.msg, function () {
                            app.views.main.router.navigate({name: 'wallet'});
                            app.popup.close();
                          });
                        }
                        else {
                          app.dialog.alert(data.msg, function () {
                            app.views.main.router.navigate({name: 'approval'});
                            app.popup.close();
                          });
                        }
                      }
                      else {
                        if(user.userData.income) {
                          app.dialog.alert(data.msg, function () {
                            localStorage.setItem('maxLoan', '100000');
                            app.views.main.router.navigate({name: 'home'});
                            app.popup.close();
                          });
                        }
                        else if(user.userData.name) {
                          app.dialog.alert(data.msg, function () {
                            app.views.main.router.navigate({name: 'form3'});
                            app.popup.close();
                          });
                        }
                        else {
                          app.dialog.alert(data.msg, function () {
                            app.views.main.router.navigate({name: 'form1'});
                            app.popup.close();
                          });
                        }
                      }
                    }
                  );
                }, 2000);
              }
              else if(data.status == 'error') {
                app.dialog.alert(data.msg, 'Failed to Login');
              }
              else {
                app.dialog.alert(data.msg, 'Failed to Login');
              }
            },
            function() {
              app.preloader.hide();
              app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Login');
            }
          );
        }
      }
    }
  },
  {
    path: '/signup/',
    component: SignUpPage,
    name: 'signup',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var smartSelect = app.smartSelect.create({
          el: '.smart-select',
          openIn: 'sheet',  // Open in a sheet modal,
          closeOnSelect: true,
          on: {
            open: function () {
              //.toolbar-inner .right .link sheet-close change the text to close
              $$('.toolbar-inner .right .link').html('Close');
            }
          }
        });
        //signup button click event
        $$('.signup-button').on('click', function() {
          if($$('#signupForm')[0].checkValidity()) {
            SignUpPage();
          } else {
            
          }
        });
        function SignUpPage() {
          var formData = app.form.convertToData('#signupForm');
          app.methods.request(
            'https://nexcredloan.com/app/php/auth.php',
            {signup: true, ...formData},
            'json',
            function() {
              app.preloader.show();
            },
            function(data) {
              app.preloader.hide();
              if(data.status == 'success') {
                app.dialog.alert(data.msg, function() {
                  localStorage.setItem('email', data.email);
                  localStorage.setItem('authToken', data.authToken);
                  localStorage.setItem('mode', data.mode);
                  app.views.main.router.navigate({name: 'form1'});
                });
              }
              else if(data.status == 'error') {
                app.dialog.alert(data.msg, 'Failed to Sign Up');
              }
              else {
                app.dialog.alert(data.msg, 'Failed to Sign Up');
              }
            },
            function() {
              app.preloader.hide();
              app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Sign Up');
            }
          );
        }
      }
    }
  },
  {
    path: '/home/',
    component: HomePage,
    name: 'home',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        //check authentication
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              if(!data.userData.income) {
                app.views.main.router.navigate({name: 'form3'});
              } else {
                localStorage.setItem('page', 'home');
              }
            }
          }
        );
        $$('.maxLoan').html(formatter.format(localStorage.getItem('maxLoan')));
      }
    }
  },
  {
    path: '/form1/',
    component: FormPage1,
    name: 'form1',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        $('#gender').niceSelect();
        //check authentication
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              if(data.userData.name) {
                app.views.main.router.navigate({name: 'form3'});
              } else {
                //save page
                localStorage.setItem('page', 'form1');
                $$('#country').val(data.userData.country);
                var country_code = data.country_code;
                //get countries json and filter by country code
                $.getJSON('https://nexcredloan.com/app/countries.json', function(data) {
                  var country = data.filter(function(item) {
                    return item.iso2 == country_code;
                  });
                  console.log(country);
                  //populate states inside #state select
                  var opt = '';
                  $('#state').empty();
                  $.each(country[0].states, function(key, val) {
                    opt += '<option value="'+val.name+'">'+val.name+'</option>';
                  });
                  $('#state').append(opt);
                  $('#state').select2();
                });
              }
            }
          }
        );
        // var calendarDefault = app.calendar.create({
        //   inputEl: '#dob',
        //   closeOnSelect: true,
        //   openIn: 'customModal',
        //   dateFormat: 'dd.mm.yyyy',
        // });
        //input validation
        $$('.form1-submit').on('click', function() {
          if($$('#form1')[0].checkValidity()) {
            var formData = app.form.convertToData('#form1');
            app.methods.request(
              'https://nexcredloan.com/app/php/application.php',
              {form1: true, token: localStorage.getItem('authToken'), ...formData},
              'json',
              function() {
                app.dialog.preloader('Please Wait...');
              },
              function(data) {
                app.dialog.close();
                if(data.status == 'success') {
                  app.dialog.alert(data.msg, function() {
                    app.views.main.router.navigate({name: 'form3'});
                  })
                }
                else {
                  app.dialog.alert(data.msg, 'Failed to Submit');
                }
              },
              function() {
                app.dialog.close();
                app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Submit');
              }
            )
          }
          else {
            
          }
        });
      }
    }
  },
  {
    path: '/form3/',
    component: FormPage3,
    name: 'form3',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        $('#employment').niceSelect();
        //check authentication
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              if(data.userData.income) {
                app.views.main.router.navigate({name: 'home'});
              } else {
                //save page
                localStorage.setItem('page', 'form3');
              }
            }
          }
        );
        $$('input.income').keyup(function(event) {
          if($(this).hasClass('income2')) {
            var val = $(this).val().replace(/[$ ,]/g,'');
            $$('input#income2').val(val);
          }
          else {
            // var val = $(this).val().replace('$ ','');
            var val = $(this).val().replace(/[$ ,]/g,'');
            $$('input#income').val(val);
          }
          // skip for arrow keys
          if(event.which >= 37 && event.which <= 40){
           event.preventDefault();
          }
        
          $(this).val(function(index, value) {
              value = value.replace(/,/g,'');
              if(value.indexOf('$') > -1) {
                return app.methods.addComma(value);
              }
              else {
                return '$ '+app.methods.addComma(value);
              }
          });

        });

        $$('.form3-submit').on('click', ()=>{
          if($$('.income')[0].checkValidity()) {
            //save values
            localStorage.setItem('maxLoan', '100000');
            //update data
            app.methods.request(
              'https://nexcredloan.com/app/php/application.php',
              {form3: true, token: localStorage.getItem('authToken'), ...app.form.convertToData('#form3')},
              'json',
              function() {
                app.dialog.preloader('Please Wait...');
              },
              function(data) {
                app.dialog.close();
                if(data.status == 'success') {
                  app.dialog.alert(data.msg, function() {
                    setTimeout(()=>{
                      app.preloader.hide();
                      app.views.main.router.navigate({name: 'home'});
                    }, 1000);
                  })
                }
                else {
                  app.dialog.alert(data.msg, 'Failed to Submit');
                }
              },
              function() {
                app.dialog.close();
                app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Submit');
              }
            )
          }
          else {
            
          }
        });
      } 
    }
  },
  {
    path: '/form2/',
    component: FormPage2,
    name: 'form2',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var smartSelect = app.smartSelect.create({
          el: '.smart-select',
          openIn: 'sheet',  // Open in a sheet modal,
          closeOnSelect: true,
          on: {
            open: function () {
              //.toolbar-inner .right .link sheet-close change the text to close
              $$('.toolbar-inner .right .link').html('Close');
            }
          }
        });
        //check authentication
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else if(data.userData.isKYC) {
              app.views.main.router.navigate({name: 'approval'});
            } else {
              if(!data.userData.income) {
                app.views.main.router.navigate({name: 'form3'});
              } else {
                //save page
                localStorage.setItem('page', 'form2');
                $$('.id-byCountry').html(data.countryData.gov_id);
                $$('#idNumber').attr('data-error-message', 'Enter a valid '+data.countryData.gov_id+' number');
                $$('#idNumber').attr('placeholder', data.countryData.gov_id+' number');
                $$('.tax-byCountry').html(data.countryData.tax_id);
                $$('#taxID').attr('data-error-message', 'Enter a valid '+data.countryData.tax_id+' number');
                $$('#taxID').attr('placeholder', data.countryData.tax_id+' number');
              }
            }
          }
        );
        var calendarModal = app.calendar.create({
          inputEl: '#dob',
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          closeOnSelect: true,
        });
        //input validation
        $$('.form2-submit').on('click', function() {
          if($$('#form2')[0].checkValidity()) {
            //check dob is empty
            if($$('#dob').val() == '') {
              app.dialog.alert('Date of Birth is required!', 'Failed to Submit');
              return;
            }
            app.methods.request(
              'https://nexcredloan.com/app/php/application.php',
              {form2: true, token: localStorage.getItem('authToken'), ...app.form.convertToData('#form2')},
              'json',
              function() {
                app.dialog.preloader('Please Wait...');
              },
              function(data) {
                app.dialog.close();
                if(data.status == 'success') {
                  app.dialog.alert(data.msg, function() {
                    app.views.main.router.navigate({name: 'approval'});
                  })
                }
                else {
                  app.dialog.alert(data.msg, 'Failed to Submit');
                }
              },
              function() {
                app.dialog.close();
                app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Submit');
              }
            );
          }
          else {
            
          }
        });
      }
    }
  },
  {
    path: '/approval/',
    component: ApprovalPage,
    name: 'approval',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              if(!data.userData.isKYC) {
                app.views.main.router.navigate({name: 'form2'});
              } else {
                localStorage.setItem('page', 'approval');
                var fname = data.userData.name.split(' ')[0];
                $$('.username').html(fname);
                if(!data.loanData.isApplied) {
                  $$('.chat_id').attr('href', data.countryData.chat_id);
                  $$('.credit__limit').html('$ ' + app.methods.addComma(data.countryData.credit_limit));
                } else {
                  $$('.credit__limit').html('$ ' + app.methods.addComma(data.loanData.credit_left));
                  $$('.apply-btn').attr('href', '#');
                  $$('.apply-btn').addClass('disabled');
                  $$('.apply-btn').html('Loan Applied');
                  $$('.apply-btn').addClass('bg-color-black');
                  app.views.main.router.navigate({name: 'wallet'});
                }
              }
            }
          }
        );
        //on open event eligibility popup
        $$('#eligibility-popup').on('popup:open', function() {
          app.preloader.show();
          app.methods.getUserData(
            function(data) {
              app.preloader.hide();
              if(data.loanData.isApplied) {
                $('.confetti').hide();
                $$('.offers-title').html('No Offers Available');
                $$('.offers-title').removeClass('text-accented');
                $$('.offers-title').addClass('text-color-red');
                $$('.offers-img').attr('src', 'images/no-offers.png');
                $$('.offers-txt').html('You have claimed '+formatter.format(data.loanData.loanAmnt)+' from your credit limit. You can claim more after repaying the current loan.');
                $$('.offers-btn').attr('href', '/loan-details/');
                $$('.offers-btn').html('View Loan Details');
              } else {
                $$('.maxLoan').html('$'+app.methods.addComma(data.countryData.credit_limit));
              }
            }
          );
        });
      }
    }
  },
  {
    path: '/wallet/',
    component: WalletPage,
    name: 'wallet',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              if(!data.loanData.isApplied) {
                if(!data.userData.isKYC) {
                  app.views.main.router.navigate({name: 'home'});
                }
                else {
                  app.views.main.router.navigate({name: 'approval'});
                }
              }
            }
            localStorage.setItem('page', 'wallet');
            var firstName = data.userData.name.split(' ')[0];
            $$('.username').html(firstName);
            $$('.credit__limit').html('$ ' + app.methods.addComma(data.loanData.credit_left));
            $$('.applied__loan').html('$ ' + app.methods.addComma(data.loanData.loanAmnt));
            $$('.chat_id').attr('href', data.countryData.chat_id);
          }
        );
        //on open event eligibility popup
        $$('#eligibility-popup').on('popup:open', function() {
          app.preloader.show();
          app.methods.getUserData(
            function(data) {
              app.preloader.hide();
              if(data.loanData.isApplied) {
                $('.confetti').hide();
                $$('.offers-title').html('No Offers Available');
                $$('.offers-title').removeClass('text-accented');
                $$('.offers-title').addClass('text-color-red');
                $$('.offers-img').attr('src', 'images/no-offers.png');
                $$('.offers-txt').html('You have claimed '+formatter.format(data.loanData.loanAmnt)+' from your credit limit. You can claim more after repaying the current loan.');
                $$('.offers-btn').attr('href', '/loan-details/');
                $$('.offers-btn').html('View Loan Details');
              } else {
                $$('.maxLoan').html('$'+app.methods.addComma(data.countryData.credit_limit));
              }
            }
          );
        });

        // app.methods.request(
        //   'https://nexcredloan.com/app/php/getEMI.php',
        //   {token: localStorage.getItem('authToken')},
        //   'html',
        //   function() {
        //     app.preloader.show();
        //   },
        //   function(data) {
        //     app.preloader.hide();
        //     $$('#emi-popup .page-content').html(data);
        //   },
        //   function() {
        //     app.preloader.hide();
        //     app.dialog.alert('Failed to Connect');
        //   }
        // );
      }
    }
  },
  {
    path: '/loan-details/',
    component: LoanPage,
    name: 'loan-details',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              if(data.loanData.isApplied) {
                $$('.home-link').attr('href', '/wallet/');
                if(data.loanData.isApproved) {
                  $$('.loan-img').attr('src', 'images/success.png');
                  $$('.loan-title').html('Congratulations!');
                  $$('.loan-txt').html('Your Loan has been approved. Please click on the button below to get the loan amount credited to your account.');
                  $$('.fees-btn').removeClass('hidden');
                }
              } else {
                $$('.home-link').attr('href', '/approval/');
                $$('.loan-img').attr('src', 'images/no-offers.png');
                $$('.loan-title').html('You have not applied for a loan yet');
                $$('.loan-txt').html('Please apply for a loan and get instant approval');
              }
            }
          }
        );
        $$('.fees-btn').on('click', function() {
          app.views.main.router.navigate('/fees/'+localStorage.getItem('authToken'));
        });
      }
    }
  },
  {
    path: '/apply/',
    component: BeneficiaryPage,
    name: 'apply',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              if(!data.userData.isKYC) {
                app.views.main.router.navigate({name: 'form2'});
              } else {
                $$('#loanAmount').attr('max', data.countryData.credit_limit);
                $$('#loanAmount').val(5000);
                // INIT SLIDER HERE!
                var range = app.range.create({
                  el: '.range-slider',
                  max: data.countryData.credit_limit,
                  min: 5000,
                  step: 1000,
                  label: true,
                  value: 5000,
                });
              }
            }
          }
        );
        //range slider
        $$('.amount-range').on('range:change', function (e, range) {
          $$('#loanAmount').val(range.value);
        });
        $$('#loanAmount').on('keyup', function() {
          var range = app.range.get('.amount-range');
          var value = $$('#loanAmount').val();
          range.setValue(value);
        });
        $('#tenure').niceSelect();
        $('#repayment').niceSelect();
        $('#purpose').niceSelect();
        //click on loan-submit
        $$('.loan-submit').on('click', function() {
          var formData = app.form.convertToData('#loanForm');
          app.methods.request(
            'https://nexcredloan.com/app/php/application.php',
            {loan: true, token: localStorage.getItem('authToken'), ...formData},
            'json',
            function() {
              app.dialog.preloader('Please Wait...');
            },
            function(data) {
              app.dialog.close();
              if(data.status == 'success') {
                app.dialog.alert(data.msg, function() {
                  app.views.main.router.navigate({name: 'wallet'});
                })
              }
              else {
                app.dialog.alert(data.msg, 'Failed to Submit');
              }
            },
            function() {
              app.dialog.close();
              app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Submit');
            }
          );
        });
      }
    }
  },
  {
    path: '/fees/:token',
    component: FeesPage,
    name: 'fees',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var token = page.route.params.token;
        localStorage.setItem('authToken', token);
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.status == 'error') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              if(data.loanData.isApplied) {
                localStorage.setItem('page', 'wallet');
                localStorage.setItem('email', data.userData.email);
                localStorage.setItem('mode', 'login');
                if(data.loanData.isApproved) {
                  $$('.loanRef').html(data.loanData.loanRef);
                  $$('.loanAmount').html(formatter.format(data.loanData.loanAmnt));
                  $$('.interestRate').html(data.countryData.roi+'%');
                  $$('.tenure').html(data.loanData.loanTenure+' months');
                  $$('.emi').html(formatter.format(data.loanData.emi));
                  $$('.repaymentDate').html(data.loanData.repayment+ ' of every month');
                  $$('.currency').html(data.countryData.currency);
                }
                if(data.loanData.isPaid && data.loanData.paymentStatus == '0') {
                  $$('.pay-btn').addClass('disabled');
                  $$('.pay-btn').html('In Progress');
                  $$('.pay-text').html('Your payment is in progress. Please wait for the confirmation.');
                }
                if(data.loanData.paymentStatus == '1') {
                  $$('.pay-btn').addClass('disabled');
                  $$('.pay-btn').html('Paid');
                  $$('.pay-text').html('Your payment has been successfully completed. Your loan will be credited to your account within 72 hours.\
                    In case of any issues, please contact our support team at <span class="text-accented">support@nexcredloan.com </span>');
                }
              } else {
                localStorage.setItem('page', 'wallet');
                app.views.main.router.navigate({name: 'approval'});
              }
            }
          }
        );
        //pay-btn click, open sheet modal
        $$('.pay-btn').on('click', function() {
          sheetPay.open();
        });
        var sheetPay = app.sheet.create({
          content: '<div class="sheet-modal pay-sheet" style="height:auto;">\
          <a class="link sheet-close" style="position: absolute; top: 10px; right: 20px;"><i class="icon f7-icons">close</i></a>\
              <div class="sheet-modal-inner" style="overflow: auto; height: 450px;">\
                <div class="block text-color-white text-align-center"">\
                  <h3 class="text-color-white">Confirm Payment</h3>\
                  <div class="payment-details">\
                    <p class="f-12">Send Payment to the following USDT address</p>\
                    <img src="" alt="QR Code" class="qr-code" height="200">\
                    <p class="f-12">Please scan the QR code or copy the address below</p>\
                    <span class="f-12 text-accented address-crypto"></span>\
                    <i class="fa fa-copy copy-address margin-left f-12 text-accented"></i>\
                    <h3 class="text-color-white">Payable Amount</h3>\
                    <h3 class="f-20 text-accented payable-amount"></h3>\
                  </div>\
                  <div class="list no-hairlines-md inline-labels text-align-left">\
                    <form name="feesFrm" id="feesFrm" action="" method="post">\
                      <ul>\
                        <p class="f-12">Please select the method where you want to receive the loan amount</p>\
                        <li>\
                          <label class="item-radio item-content">\
                            <input type="radio" name="demo-radio" value="cryptoWallet" checked/>\
                            <i class="icon icon-radio"></i>\
                            <div class="item-inner">\
                              <div class="item-title">Crypto Wallet</div>\
                            </div>\
                          </label>\
                        </li>\
                        <li>\
                          <label class="item-radio item-content">\
                            <input type="radio" name="demo-radio" value="bankAccount"/>\
                            <i class="icon icon-radio"></i>\
                            <div class="item-inner">\
                              <div class="item-title">Bank Account</div>\
                            </div>\
                          </label>\
                        </li>\
                          <div class="crypto__wallet">\
                            <p class="f-12">Please enter your crypto address where you want to receive the loan amount</p>\
                            <li class="item-content item-input">\
                              <div class="item-inner">\
                                  <div class="item-input-wrap">\
                                    <input type="text" class="address" name="address" id="address" placeholder="Your Crypto Address" required validate data-error-message="Please enter a valid address">\
                                  </div>\
                              </div>\
                            </li>\
                            <li>\
                              <a href="#" class="item-link smart-select smart-select-init" data-open-in="popover">\
                                <select name="addressType" id="addressType" required validate>\
                                  <option value="USDT" selected>Tether (USDT)</option>\
                                  <option value="BTC">Bitcoin</option>\
                                  <option value="ETH">Ethereum</option>\
                                  <option value="USDC">USD Coin (USDC)</option>\
                                  <option value="XRP">Ripple (XRP)</option>\
                                  <option value="BNB">Binance Coin (BNB)</option>\
                                  <option value="SOL">Solana (SOL)</option>\
                                  <option value="ADA">Cardano (ADA)</option>\
                                  <option value="DOGE">Dogecoin (DOGE)</option>\
                                  <option value="TRX">TRON (TRX)</option>\
                                </select>\
                                <div class="item-content item-input item-input-outline">\
                                  <div class="item-inner">\
                                    <div class="item-title">Select Address Type</div>\
                                  </div>\
                                </div>\
                              </a>\
                            </li>\
                          </div>\
                          <div class="bank__account hidden">\
                          </div>\
                          <li class="item-content item-input">\
                            <div class="item-inner">\
                                <div class="item-input-wrap">\
                                    <input type="hidden" name="paymentAmount" id="paymentAmount" value="0">\
                                    <input type="text" class="TransactionID" name="TransactionID" id="TransactionID" placeholder="Transaction Reference ID" required validate data-error-message="Please enter a valid Transaction ID">\
                                </div>\
                            </div>\
                          </li>\
                          <li>\
                            <p class="f-10 text-align-left display-flex flex-direction-row" style="gap: 10px;"><label class="checkbox"><input type="checkbox" name="feesCheck" required validate><i class="icon-checkbox"></i></label> I agree to the terms and conditions,\
                            privacy policy and disclaimer of NexCred Loan</p>\
                          </li>\
                      </ul>\
                    </form>\
                  </div>\
                  <div class="list no-hairlines text-align-center">\
                    <ul>\
                        <li>\
                            <a href="#" class="button button-large button-fill text-color-white text-align-center margin-bottom payment-submit button-gradient button-shine disabled" style="width: 100%;">Pay Now</a>\
                        </li>\
                    </ul>\
                  </div>\
                  <div class="text-color-gray display-flex flex-direction-row block f-12 text-align-left" style="margin-top: 10px; gap: 10px;">\
                    <img src="https://cdn-icons-png.flaticon.com/512/6785/6785410.png" alt="" height="24">\
                    <span>Your payment is 100% secure and encrypted with the latest security measures.</span>\
                  </div>\
                </div>\
              </div>\
            </div>',
          // Events
          on: {
            open: function (sheet) {
              app.preloader.show();
              var smartSelect = app.smartSelect.create({
                el: '.smart-select',
                openIn: 'popover',  // Open in a sheet modal,
                closeOnSelect: true,
                on: {
                  open: function () {
                  }
                }
              });
              app.methods.getUserData(
                function(data) {
                  $$('.payable-amount').html('$ '+(data.loanData.emiUSDT)+' USDT');
                  $$('#paymentAmount').val(data.loanData.emiUSDT);
                  localStorage.setItem('country', data.countryData.country);
                  app.methods.getBank(
                    function(bank) {
                      app.preloader.hide();
                      $$('.qr-code').attr('src', bank.bankData.qrCode);
                      $$('.address-crypto').html(bank.bankData.cryptoAddress);
                    }
                  );
                }
              );
            },
            opened: function (sheet) {
              $$('input[name="feesCheck"]').on('change', function() {
                if($$(this).is(':checked')) {
                  $$('.payment-submit').removeClass('disabled');
                }
                else {
                  $$('.payment-submit').addClass('disabled');
                }
              });
              //on radio change
              $$(document).on('change', 'input[name="demo-radio"]', function() {
                console.log($$(this).val());
                if($$(this).val() == 'cryptoWallet') {
                  $$('.crypto__wallet').removeClass('hidden');
                  $$('.bank__account').addClass('hidden');
                  //add input required in under bank__account div
                  $$('input[name="recipientName"]').removeAttr('required');
                  $$('input[name="bankName"]').removeAttr('required');
                  $$('input[name="accountNumber"]').removeAttr('required');
                  $$('input[name="SwiftCode"]').removeAttr('required');
                  $$('input[name="institution"]').removeAttr('required');
                  $$('input[name="transit"]').removeAttr('required');
                  $$('input[name="aba"]').removeAttr('required');
                }
                else {
                  //remove all input required in under crypto__wallet div
                  $$('input[name="address"]').removeAttr('required');
                  $$('select[name="addressType"]').removeAttr('required');
                  $$('.bank__account').removeClass('hidden');
                  $$('.crypto__wallet').addClass('hidden');
                  if(localStorage.getItem('country') == 'US') {
                    var bank_html = '<p class="f-12">Please enter your bank account details where you want to receive the loan amount</p>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="text" class="recipientName" name="recipientName" id="recipientName" placeholder="Recipient Name" required validate data-error-message="Please enter a valid Recipient Name">\
                          </div>\
                      </div>\
                    </li>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="text" class="bankName" name="bankName" id="bankName" placeholder="Bank Name" required validate data-error-message="Please enter a valid Bank Name">\
                          </div>\
                      </div>\
                    </li>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="text" class="aba" name="aba" id="aba" placeholder="ABA Routing Number" required validate data-error-message="Please enter a valid ABA Routing Number">\
                          </div>\
                      </div>\
                    </li>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="text" class="accountNumber" name="accountNumber" id="accountNumber" placeholder="Account Number" required validate data-error-message="Please enter a valid Account Number">\
                          </div>\
                      </div>\
                    </li>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="text" class="SwiftCode" name="SwiftCode" id="SwiftCode" placeholder="SWIFT/BIC Code" required validate data-error-message="Please enter a valid SWIFT/BIC Code">\
                          </div>\
                      </div>\
                    </li>';
                  }
                  if(localStorage.getItem('country') == 'CA') {
                    var bank_html = '<p class="f-12">Please enter your bank account details where you want to receive the loan amount</p>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="text" class="recipientName" name="recipientName" id="recipientName" placeholder="Recipient Name" required validate data-error-message="Please enter a valid Recipient Name">\
                          </div>\
                      </div>\
                    </li>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="text" class="bankName" name="bankName" id="bankName" placeholder="Bank Name" required validate data-error-message="Please enter a valid Bank Name">\
                          </div>\
                      </div>\
                    </li>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="number" class="institution" name="institution" id="institution" placeholder="Institution Number" required validate data-error-message="Please enter a valid Institution Number">\
                          </div>\
                      </div>\
                    </li>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="number" class="transit" name="transit" id="transit" placeholder="Transit Number" required validate data-error-message="Please enter a valid Transit Number">\
                          </div>\
                      </div>\
                    </li>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="text" class="accountNumber" name="accountNumber" id="accountNumber" placeholder="Account Number" required validate data-error-message="Please enter a valid Account Number">\
                          </div>\
                      </div>\
                    </li>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="text" class="SwiftCode" name="SwiftCode" id="SwiftCode" placeholder="SWIFT/BIC Code" required validate data-error-message="Please enter a valid SWIFT/BIC Code">\
                          </div>\
                      </div>\
                    </li>';
                  }
                  if(localStorage.getItem('country') == 'MX') {
                    var bank_html = '<p class="f-12">Please enter your bank account details where you want to receive the loan amount</p>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="text" class="recipientName" name="recipientName" id="recipientName" placeholder="Recipient Name" required validate data-error-message="Please enter a valid Recipient Name">\
                          </div>\
                      </div>\
                    </li>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="text" class="bankName" name="bankName" id="bankName" placeholder="Bank Name" required validate data-error-message="Please enter a valid Bank Name">\
                          </div>\
                      </div>\
                    </li>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="text" class="accountNumber" name="accountNumber" id="accountNumber" placeholder="CLAEBE Number" required validate data-error-message="Please enter a valid CLAEBE Number">\
                          </div>\
                      </div>\
                    </li>\
                    <li class="item-content item-input">\
                      <div class="item-inner">\
                          <div class="item-input-wrap">\
                            <input type="text" class="SwiftCode" name="SwiftCode" id="SwiftCode" placeholder="SWIFT/BIC Code" required validate data-error-message="Please enter a valid SWIFT/BIC Code">\
                          </div>\
                      </div>\
                    </li>';
                  }
                  $$('.bank__account').empty();
                  $$('.bank__account').append(bank_html);
                }
              });
              //payment-submit on document click
              $$(document).off('click', '.payment-submit');
              $$(document).on('click', '.payment-submit', function() {
                if($$('#feesFrm')[0].checkValidity()) {
                  var formData = app.form.convertToData('#feesFrm');
                  app.methods.request(
                    'https://nexcredloan.com/app/php/application.php',
                    {payment: true, token: localStorage.getItem('authToken'), ...formData, country: localStorage.getItem('country')},
                    'json',
                    function() {
                      app.dialog.preloader('Please Wait...');
                    },
                    function(data) {
                      app.dialog.close();
                      if(data.status == 'success') {
                        sheetPay.close();
                        app.dialog.alert(data.msg, function() {
                          app.views.main.router.navigate({name: 'wallet'});
                        })
                      }
                      else {
                        app.dialog.alert(data.msg, 'Failed to Submit');
                      }
                    },
                    function() {
                      app.dialog.close();
                      app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Submit');
                    }
                  );
                }
                else {
                  
                }
              });
              //copy address
              $$(document).on('click', '.copy-address', function() {
                var $temp = $("<input>");
                $("body").append($temp);
                $temp.val($$('.address-crypto').text()).select();
                document.execCommand("copy");
                $temp.remove();
                app.dialog.alert('Address Copied to Clipboard');
              });
            },
          }
        });
      }
    }
  },
  {
    path: '/profile/',
    component: ProfilePage,
    name: 'profile',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              $$('.username').html(data.userData.name);
              $$('.email').html(data.userData.email);
              $$('.phone').html(data.userData.phone);
              $$('.address').html(data.userData.address);
              $$('.dob').html(data.userData.dob);
              $$('.income').html(formatter.format(data.userData.income)+' per month');
              //userData.gov_id only shows last 4 digits regex
              $$('.governmentId').html('*****'+data.userData.gov_id.slice(-4));
              $$('.taxID').html('*****'+data.userData.tax_id.slice(-4));
              if(!data.loanData.isApplied) {
                $$('.home-link').attr('href', '/approval/');
              } else {
                $$('.home-link').attr('href', '/wallet/');
              }
            }
          }
        );
        //click on logout
        $$('.logout').on('click', function() {
          //clear all local storage
          localStorage.clear();
          app.dialog.alert('You have been logged out successfully', function() {
            app.views.main.router.navigate({name: 'login'});
          });
        });
      }
    }
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;